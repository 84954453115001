.hero {
    background-image: url('../images/banner-A-v1.webp?width=3800&height=1900');
}


@media (max-width: 1920px) { 
  .hero {
    background-image: url('../images/banner-A-v1.webp?width=2080&height=1120');
  }
}

@media (max-width: 1300px) {  
  .hero {
    background-image: url('../images/banner-A-v1.webp?width=1920&height=1080');
    padding-top: calc($nav-height);
  }
}

@media (max-width: 650px) {
  .hero {
    background-image: url('../images/banner-A-v1.webp?width=1000&height=1520');   
  }
  
}

.hero {
    height: 95vh;
    will-change: background-position; 
    background-size: cover; 
    position: relative;
    background-position: center;
    @include flex(column, center, center);
    overflow: hidden;
    padding-top: calc($nav-height);
    overflow: visible;
  
    .container {
      z-index: 10;
    }
  
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 10vh;
    }
  
    &::before {
      top: 0;
      background: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
      height: 20vh;
    }
  
    &::after {
      bottom: 0;
      height: 30vh;
      background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
    }
  
    &__content {
      text-align: center;
      z-index: 1;
      width: 100%;
      max-width: 1200px;
      @include flex(column, center, center);
    }
  
    &__title {
      font-family: $font-display;
      font-size: clamp(2.25rem, 6vw, 5rem);
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 1rem;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
      max-width: 100%;
      overflow-wrap: break-word;
      hyphens: auto;
    }
  
    &__subtitle {
      font-size: clamp(.9rem, 3vw, 1.5rem);
      font-weight: 600;
      letter-spacing: clamp(1px, 2vw, 11px);
      text-transform: uppercase;
      margin-bottom: clamp(50px, 10vh, 100px);
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    }
  
    &__cta {
      padding: clamp(12px, 2vw, 15px) clamp(35px, 5vw, 50px);
      font-size: clamp(1.2rem, 2vw, 1.6rem);
      text-shadow: 0 1px 1px #000;
      @include button-style($color-secondary, $color-brightest, rgb(0, 0, 0));

    }
  }
  
  // Countdown
  .countdown {
    @include flex(row, center, center);
    white-space: nowrap;
    gap: clamp(1.5rem,2vw,2.5rem);
    margin-top: 100px;
  
    &__item {
      text-align: center;
    }
  
    &__number {
      font-family: $font-display;
      text-align: center;
      font-size: clamp(2rem, 5vw, 5rem);
      color: $color-primary;
      min-width: clamp(calc(2.3rem * 2), calc(6vw * 2), calc(6rem * 2));
      display: inline-block;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    }
  
    &__label {
      font-size: clamp(0.9rem, 2vw, 2rem);
      font-weight:600;
      text-transform: uppercase;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
      margin-top:0.5rem
    }
  }

  @media (max-width: 1300px) {  
    .hero {
      min-height: 85vh;
      padding-top: $nav-height-mobile;
    }
  }

  @media (max-width: 650px) {
    .hero {
      min-height: 85vh;
      justify-content: flex-end;
      padding-bottom: 0;
    }
  
    .hero__title {
      font-size: clamp(1.5rem, 6.5vw, 4rem);
      padding: 0 10px;
    }
  
    .hero__subtitle {
      max-width: 65vw;
      letter-spacing: clamp(1px, 1.5vw, 10px);
      margin-bottom: clamp(25px, 5vh, 60px);
    }
  
    .countdown {
      gap: clamp(0.25rem, 1vw, 1rem);
  
      &__number {
        font-size: clamp(2rem, 5vw, 4rem);
        min-width: clamp(calc(2.5rem * 2), calc(6vw * 2), calc(6rem * 2));
      }
  
      &__label {
        font-size: clamp(0.8rem, 1.8vw, 1.5rem);
      }
    }
  }